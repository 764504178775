import React, { FunctionComponent, useEffect, useState } from "react";
import { ICreateProject, IQuestionItem, ISelect } from "utils/models";
import ProjectsConnection from "utils/connections/projects";
import { generatePath, useHistory, useParams } from "react-router-dom";
import {
  NotCancelErrorPromise,
  deepClone,
  doubleSidePickerOnChange,
  handleSetErrors,
  handleToast,
  useQuery,
} from "utils/helpers";
import { createProjectState } from "utils/states";
import WhiteCard from "global/atoms/WhiteCard";
import Select from "global/atoms/Select";
import styles from "./styles.module.scss";
import Input from "global/atoms/Input";
import ActionsContainer from "global/atoms/ActionsContainer";
import SeparatorEmpty from "global/atoms/separators/SeparatorEmpty";
import Section from "global/atoms/section/Section";
import CheckBox from "../../../../global/atoms/checkbox/CheckBox";
import LocalDatePicker from "../../../../global/atoms/LocalDatePicker";
import SpinnerSmall from "../../../../global/atoms/Spinner/SpinnerSmall";
import SeparatorLine from "../../../../global/atoms/separators/SeparatorLine";
import { AccessRoute } from "./Access";
import {
  Button,
  Message,
  Col,
  Row,
  Slider,
  Grid,
  Toggle,
  Form,
  Panel,
} from "rsuite";
import DoubleSidePicker from "../../../../global/atoms/dooubleSidePicker/DoubleSidePicker";
import UniversalModal, {
  getQuestionConfigTitle,
} from "../../../../global/atoms/UniversalModal";
import QuestionForm from "../taskActivitiesEdit/QuestionForm";
import { beforeToday } from "rsuite/esm/DateRangePicker/disabledDateUtils";
import FolderPathPicker from "./FolderPathPicker";
import PhotoWatermarkPicker from "./PhotoWatermarkPicker";

interface IForm {
  header: {
    name: string;
    editItemTitle: string;
    actions: [];
  };
  fields: [];
  actions: [];
  customers: any;
  distributors: ISelect;
  projectStatuses: any;
  calcDocumentTypes: any;
  unassignedQuestions: { count: number; data: Array<IQuestionItem> };
  assignedQuestions: { count: number; data: Array<IQuestionItem> };
  paymentTypes: ISelect;
  deviceTypes: ISelect;
  organization: {
    isEnableWaterMarkProjectConfig: boolean;
    isEnableCheckRoute: boolean;
  };
}

const Basic: FunctionComponent = () => {
  const [data, setData] = useState<ICreateProject>(createProjectState);
  const [form, setForm] = useState<IForm>({
    header: {
      name: "",
      editItemTitle: "",
      actions: [],
    },
    fields: [],
    actions: [],
    customers: [],
    projectStatuses: [],
    distributors: { options: [], value: null },
    calcDocumentTypes: [],
    unassignedQuestions: { count: 0, data: [] },
    assignedQuestions: { count: 0, data: [] },
    paymentTypes: { options: [], value: null },
    deviceTypes: { options: [], value: null },
    organization: {
      isEnableWaterMarkProjectConfig: false,
      isEnableCheckRoute: false,
    },
  });

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<any>({});
  const onTrip = useQuery().get("trip") !== null;
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const handleStatusChange = (ev: any) => {
    const status = form?.projectStatuses?.options?.find(
      (el: any) => el.id === ev.value
    );
    if (status?.status == "ACTIVE") {
      setData((data) => ({
        ...data,
        projectStatusId: ev.value,
        endDate: null,
      }));
    } else {
      setData((data) => ({ ...data, projectStatusId: ev.value }));
    }
  };

  useEffect(() => {
    ProjectsConnection.getFormEditProject(id).then((data) => {
      setData((state: ICreateProject) => {
        const projectSettings =
          data.data.model.projectSettings?.id !== undefined
            ? data.data.model.projectSettings
            : state.projectSettings;
        if (projectSettings.numberOrderPattern === "") {
          projectSettings.numberOrderPattern =
            data.data.defaultData.numberOrderPattern;
        }
        if (projectSettings.numberDocumentPattern === "") {
          projectSettings.numberDocumentPattern =
            data.data.defaultData.numberDocumentPattern;
        }
        if (projectSettings.deviceType == "TERMINAL") {
          projectSettings.calcDocumentType = "ITEM";
        }
        return {
          ...state,
          name: data.data.model.name,
          customerId: data.data.model.customer.id,
          projectStatusId: data.data.model.projectStatus.id,
          startDate: data.data.model.startDate,
          endDate: data.data.model.endDate,
          projectSettings:
            data.data.model.projectSettings?.id !== undefined
              ? data.data.model.projectSettings
              : state.projectSettings,
        };
      });
      setForm(data.data);
      setLoading(false);
    }, NotCancelErrorPromise);
  }, []);

  const handleSubmit = () => {
    const dataClone = deepClone(data);
    dataClone.locationQuestions = {
      all: true,
      assignedCount: form.assignedQuestions.data.length,
      assigned: form.assignedQuestions.data,
      unassigned: form.unassignedQuestions.data,
    };
    dataClone.projectSettings = {
      ...dataClone.projectSettings,
      antennaDistance: parseInt(
        dataClone.projectSettings?.antennaDistance?.toString() ?? "800"
      ),
      gpsDistance: parseInt(
        dataClone.projectSettings?.gpsDistance?.toString() ?? "800"
      ),
    };
    handleToast(ProjectsConnection.updateProject(dataClone, id))
      .then(() => {
        if (onTrip) {
          history.push(
            generatePath(`${AccessRoute.path}?trip`, {
              id: id,
            })
          );
        } else {
          // stay here
        }
      })
      .catch((err) => handleSetErrors(err, setErrors));
  };

  const handleInputChange = (
    name: string,
    value: number | string,
    _parseInt = false
  ) => {
    setData({
      ...data,
      projectSettings: {
        ...data.projectSettings,
        [name]: _parseInt ? parseInt(value.toString()) : value,
      },
    });
  };

  const handleChangeProjectBoolSettings = (name: string) => {
    const projectSettings: any = { ...data.projectSettings };
    projectSettings[name] = !projectSettings[name];
    setData({ ...data, ...{ projectSettings: projectSettings } });
  };

  const handleProjectSettingsSelectChange = (name, value) => {
    const projectSettings: any = { ...data.projectSettings };
    projectSettings[name] = value;
    if (projectSettings.deviceType == "TERMINAL") {
      projectSettings.calcDocumentType = "ITEM";
    }
    setData({ ...data, ...{ projectSettings: projectSettings } });
  };

  const projectSetColMultiplier =
    data.projectSettings.isEnableInvoice && data.projectSettings.isEnableOrder
      ? 1
      : 2;

  const [modalOpen, setModalOpen] = useState(false);
  const [pickedQuestion, setPickedQuestion] = useState<IQuestionItem>();
  const [clearPickedQuestionTrigger, setClearPickedQuestionTrigger] =
    useState(0);

  const handlePickedQuestion = (questionId: string) => {
    // @ts-ignore
    const question: IQuestionItem = form.assignedQuestions.data.find(
      (q) => q.id === questionId
    );
    if (!question) throw "handlePickedQuestion - picked question not found";
    setPickedQuestion(question);
    setModalOpen(true);
  };

  const waterMarkOptions = () => {
    return (
      <Col md={24} xs={24}>
        <Section title={"Znak wodny opcje"}>
          <Col xs={6}>
            <Toggle
              checkedChildren={"Znak wodny włączony"}
              unCheckedChildren={"Zdjęcie bez znaku wodnego"}
              checked={data.projectSettings.isPhotoWatermarkEnabled}
              style={{ marginTop: "10px", width: "100%" }}
              onChange={(value) => {
                setData((d) => ({
                  ...d,
                  projectSettings: {
                    ...d.projectSettings,
                    isPhotoWatermarkEnabled: value,
                    isPhotoWatermarkOutside: !value
                      ? false
                      : d.projectSettings.isPhotoWatermarkOutside,
                  },
                }));
              }}
            />

            {data.projectSettings.isPhotoWatermarkEnabled && (
              <>
                <SeparatorEmpty />
                <Toggle
                  checkedChildren={
                    data.projectSettings.isPhotoWatermarkEnabled
                      ? "Znak wodny pod zdjęciem"
                      : "Bez znaku wodnego"
                  }
                  unCheckedChildren={
                    data.projectSettings.isPhotoWatermarkEnabled
                      ? "Znak wodny na zdjęciu"
                      : "Bez znaku wodnego"
                  }
                  checked={data.projectSettings.isPhotoWatermarkOutside}
                  style={{ marginTop: "10px", width: "100%" }}
                  onChange={(value) => {
                    setData({
                      ...data,
                      projectSettings: {
                        ...data.projectSettings,
                        isPhotoWatermarkOutside: value,
                      },
                    });
                  }}
                />
              </>
            )}
            <SeparatorEmpty />
          </Col>
          <Col xs={12}>
            {data.projectSettings.isPhotoWatermarkEnabled && (
              <PhotoWatermarkPicker
                data={data}
                setData={setData}
                shortConfig={!form.organization.isEnableWaterMarkProjectConfig}
              />
            )}
          </Col>
        </Section>
      </Col>
    );
  };
  const getModalBody = (): JSX.Element => {
    if (!pickedQuestion) return <></>;
    return (
      <>
        <QuestionForm
          question={pickedQuestion}
          assigned={form.assignedQuestions.data}
          removePicked={() => {
            setModalOpen(false);
            setClearPickedQuestionTrigger(new Date().getTime());
          }}
          setForm={setForm}
          form={form}
          defaultValueEdit={false}
          type={"project"}
        />
      </>
    );
  };
  return (
    <>
      <WhiteCard padding={true}>
        {!data || !form || loading ? (
          <SpinnerSmall />
        ) : (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={8} md={24} xs={24}>
                <Input
                  type={"text"}
                  name={"name"}
                  value={data.name}
                  placeholder={"Nazwa projektu"}
                  setState={setData}
                  state={data}
                  errors={errors}
                />
              </Col>
              <Col lg={8} md={24} xs={24}>
                <Select
                  placeholder={"Klient"}
                  disabled={false}
                  name={"customerId"}
                  value={data.customerId}
                  options={form.customers.options}
                  setState={setData}
                  state={data}
                  errors={errors}
                />
              </Col>
              <Col lg={8} md={24} xs={24}>
                <Select
                  placeholder={"Status"}
                  disabled={false}
                  name={"projectStatusId"}
                  value={data.projectStatusId}
                  options={form.projectStatuses.options}
                  setState={setData}
                  state={data}
                  errors={errors}
                  onChange2={handleStatusChange}
                />
              </Col>
            </Row>
            <SeparatorEmpty />
            <Section title={"Parametry projektu"}>
              <Row>
                <Col lg={12} md={24} xs={24}>
                  <CheckBox
                    isChecked={data.projectSettings.isEnableInvoice}
                    onClick={handleChangeProjectBoolSettings.bind(
                      null,
                      "isEnableInvoice"
                    )}>
                    Sprzedaż
                  </CheckBox>
                  <CheckBox
                    isChecked={data.projectSettings.isEnableOrder}
                    onClick={handleChangeProjectBoolSettings.bind(
                      null,
                      "isEnableOrder"
                    )}>
                    Zamówienia
                  </CheckBox>
                  <CheckBox
                    isChecked={data.projectSettings.isEnableStoreDocument}
                    onClick={handleChangeProjectBoolSettings.bind(
                      null,
                      "isEnableStoreDocument"
                    )}>
                    Wydanie
                  </CheckBox>
                  <SeparatorEmpty size={1} />
                  <CheckBox
                    isChecked={data.projectSettings.isEnableGlobalTimer}
                    onClick={handleChangeProjectBoolSettings.bind(
                      null,
                      "isEnableGlobalTimer"
                    )}>
                    Globalny pomiar czasu
                  </CheckBox>
                  {form.organization.isEnableCheckRoute && (
                    <CheckBox
                      isChecked={data.projectSettings.isEnableCheckRoute}
                      onClick={handleChangeProjectBoolSettings.bind(
                        null,
                        "isEnableCheckRoute"
                      )}>
                      Rejestracja trasy GPS
                    </CheckBox>
                  )}
                </Col>
                <Col lg={12} md={24} xs={24}>
                  <Row>
                    <LocalDatePicker
                      label={"Data rozpoczęcia projektu"}
                      state={data}
                      stateKey={"startDate"}
                      emptyValue={null}
                      setState={setData}
                      errors={errors}
                      disabledDate={beforeToday()}
                    />
                  </Row>
                  <Row>
                    <LocalDatePicker
                      label={"Data zakończenia projektu"}
                      state={data}
                      stateKey={"endDate"}
                      emptyValue={null}
                      setState={setData}
                      errors={errors}
                      disabledDate={(date: Date) =>
                        date <
                          new Date(new Date().toISOString().slice(0, 10)) ||
                        date < new Date((data.startDate ?? 0) * 1000)
                      }
                    />
                  </Row>
                  <Row>
                    <Input
                      name="salePrefix"
                      value={data.projectSettings.salePrefix}
                      placeholder={"Nazwa skrócona projektu (PREFIX)"}
                      onChange={(event, value) =>
                        handleInputChange("salePrefix", value)
                      }
                      state={data}
                      errors={errors?.projectSettings}
                    />
                  </Row>
                  <Row>
                    <Input
                      placeholder={
                        "Po jakim czasie od wypełnienia, można zgłaszać ankiety do poprawy (dni)"
                      }
                      type="number"
                      min={0}
                      name={"improveAvailableDays"}
                      onChange={(event, value) =>
                        handleInputChange("improveAvailableDays", value, true)
                      }
                      state={data}
                      errors={errors?.projectSettings}
                      value={data.projectSettings.improveAvailableDays}
                    />
                  </Row>
                  <Row>
                    <Input
                      placeholder={"Czas na poprawe ankiety (dni)"}
                      type="number"
                      min={0}
                      name={"improveExpirationDays"}
                      onChange={(event, value) =>
                        handleInputChange("improveExpirationDays", value, true)
                      }
                      state={data}
                      errors={errors?.projectSettings}
                      value={data.projectSettings.improveExpirationDays}
                    />
                  </Row>
                </Col>
              </Row>
            </Section>
            <Row>
              <Col lg={12} md={24} xs={24}>
                <Panel
                  bordered
                  header={"Aplikacja mobilna"}
                  style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <CheckBox
                    isChecked={data.projectSettings.isEnableVisitOutOfPlan}
                    onClick={handleChangeProjectBoolSettings.bind(
                      null,
                      "isEnableVisitOutOfPlan"
                    )}>
                    Wizyty poza planem
                  </CheckBox>

                  <CheckBox
                    isChecked={data.projectSettings.isEnableEditLocation}
                    onClick={handleChangeProjectBoolSettings.bind(
                      null,
                      "isEnableEditLocation"
                    )}>
                    Edycja lokalizacji
                  </CheckBox>

                  <CheckBox
                    isChecked={data.projectSettings.isEnableCreateLocation}
                    onClick={handleChangeProjectBoolSettings.bind(
                      null,
                      "isEnableCreateLocation"
                    )}>
                    Dodawanie lokalizacji
                  </CheckBox>
                  <CheckBox
                    isChecked={data.projectSettings.isEnableImprovePush}
                    onClick={handleChangeProjectBoolSettings.bind(
                      null,
                      "isEnableImprovePush"
                    )}>
                    Powiadomienie push o wizycie do poprawy
                  </CheckBox>
                </Panel>
              </Col>
              <Col lg={12} md={24} xs={24}>
                <Panel
                  bordered
                  header={"Aplikacja mobilna - edycja lokalizacji"}
                  style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <CheckBox
                    isChecked={data.projectSettings.isRequiredOnLocationNetwork}
                    onClick={handleChangeProjectBoolSettings.bind(
                      null,
                      "isRequiredOnLocationNetwork"
                    )}>
                    Sieć
                  </CheckBox>

                  <CheckBox
                    isChecked={data.projectSettings.isRequiredOnLocationNip}
                    onClick={handleChangeProjectBoolSettings.bind(
                      null,
                      "isRequiredOnLocationNip"
                    )}>
                    NIP
                  </CheckBox>

                  <CheckBox
                    isChecked={
                      data.projectSettings.isRequiredOnLocationPostcode
                    }
                    onClick={handleChangeProjectBoolSettings.bind(
                      null,
                      "isRequiredOnLocationPostcode"
                    )}>
                    Kod pocztowy
                  </CheckBox>

                  <CheckBox
                    isChecked={data.projectSettings.isRequiredOnLocationType}
                    onClick={handleChangeProjectBoolSettings.bind(
                      null,
                      "isRequiredOnLocationType"
                    )}>
                    Typ lokalizacji
                  </CheckBox>

                  <CheckBox
                    isChecked={
                      data.projectSettings.isRequiredOnLocationVoivodeship
                    }
                    onClick={handleChangeProjectBoolSettings.bind(
                      null,
                      "isRequiredOnLocationVoivodeship"
                    )}>
                    Województwo
                  </CheckBox>

                  <CheckBox
                    isChecked={
                      data.projectSettings.isRequiredOnLocationCommunity
                    }
                    onClick={handleChangeProjectBoolSettings.bind(
                      null,
                      "isRequiredOnLocationCommunity"
                    )}>
                    Gmina
                  </CheckBox>

                  <CheckBox
                    isChecked={
                      data.projectSettings.isRequiredOnLocationDistrict
                    }
                    onClick={handleChangeProjectBoolSettings.bind(
                      null,
                      "isRequiredOnLocationDistrict"
                    )}>
                    Powiat
                  </CheckBox>
                </Panel>
              </Col>
            </Row>
            <Row>
              <Col md={24}>
                {(data.projectSettings.isEnableInvoice ||
                  data.projectSettings.isEnableOrder) && (
                  <>
                    <br />
                    <Message
                      type={"info"}
                      header={
                        <div style={{ textAlign: "center" }}>
                          Budowa numeracji dokumentów/zamówień
                        </div>
                      }>
                      <div className={styles.configContainer}>
                        <div className={styles.configContainerBox}>
                          <b>/</b>
                          <br /> separator
                        </div>
                        <div className={styles.configContainerBox}>
                          <b>PREFIX</b>
                          <br /> dowolny ciąg znaków alfanumerycznych
                        </div>
                        <div className={styles.configContainerBox}>
                          <b>NR</b>
                          <br /> kolejny numer dokumentu
                        </div>
                        <div className={styles.configContainerBox}>
                          <b>MM</b>
                          <br /> miesiąc
                        </div>
                        <div className={styles.configContainerBox}>
                          <b>RRRR</b> <br /> rok
                        </div>
                        <div className={styles.configContainerBox}>
                          <b>RR</b>
                          <br /> rok (skrócony)
                        </div>
                        <div className={styles.configContainerBox}>
                          <b>TYPE</b>
                          <br /> nazwa rodzaju dokumentu (FV, PAR, ZAM)
                        </div>
                        <div className={styles.configContainerBox}>
                          <b>USER</b>
                          <br /> unikalny kod użytkownika, który wystawił
                          dokument
                        </div>
                      </div>
                    </Message>
                  </>
                )}
              </Col>
            </Row>

            {(data.projectSettings.isEnableInvoice ||
              data.projectSettings.isEnableOrder) && (
              <>
                <SeparatorEmpty size={1.5} className={styles.separator} />
                <Row>
                  {data.projectSettings.isEnableInvoice && (
                    <>
                      <Col lg={12 * projectSetColMultiplier} md={24} xs={24}>
                        <Section title={"Opcje sprzedaży"}>
                          <Row>
                            <Col lg={12} md={24} xs={24}>
                              <Input
                                name="numberDocumentPattern"
                                value={
                                  data.projectSettings.numberDocumentPattern
                                }
                                placeholder={"Szablon numeracji dokumentów"}
                                onChange={(event, value) =>
                                  handleInputChange(
                                    "numberDocumentPattern",
                                    value
                                  )
                                }
                                state={data}
                                errors={errors?.projectSettings}
                              />
                            </Col>
                            <Col lg={12} md={24} xs={24}>
                              <Select
                                placeholder={
                                  "Podatek w dokumentach liczony od:"
                                }
                                disabled={
                                  data.projectSettings.deviceType == "TERMINAL"
                                }
                                name={"calcDocumentType"}
                                value={data.projectSettings.calcDocumentType}
                                options={form.calcDocumentTypes.options}
                                errors={errors}
                                onChange={handleProjectSettingsSelectChange.bind(
                                  null,
                                  "calcDocumentType"
                                )}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12} md={24} xs={24}>
                              <Select
                                placeholder={"Rodzaj urządzenia drukującego"}
                                name={"deviceType"}
                                value={data.projectSettings.deviceType}
                                options={form.deviceTypes.options}
                                errors={errors}
                                onChange={handleProjectSettingsSelectChange.bind(
                                  null,
                                  "deviceType"
                                )}
                              />
                            </Col>
                            <Col lg={12} md={24} xs={24}>
                              <Select
                                placeholder={"Typ płatności"}
                                name={"paymentType"}
                                value={data.projectSettings.paymentType}
                                options={form.paymentTypes.options}
                                errors={errors}
                                onChange={handleProjectSettingsSelectChange.bind(
                                  null,
                                  "paymentType"
                                )}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12} md={12} xs={12}>
                              <Input
                                type={"number"}
                                name="showDocumentCount"
                                value={data.projectSettings.showDocumentCount}
                                placeholder={"Ilość dokumentów historycznych"}
                                onChange={(event, value) =>
                                  handleInputChange(
                                    "showDocumentCount",
                                    value,
                                    true
                                  )
                                }
                                state={data}
                                errors={errors?.projectSettings}
                              />
                            </Col>
                            <Col lg={12} md={12} xs={12}>
                              <Input
                                type="number"
                                name="prepareDocumentCount"
                                value={
                                  data.projectSettings.prepareDocumentCount
                                }
                                placeholder={"Max ilość dokumentów sprzedaży"}
                                onChange={(event, value) =>
                                  handleInputChange(
                                    "prepareDocumentCount",
                                    value,
                                    true
                                  )
                                }
                                state={data}
                                errors={errors?.projectSettings}
                              />
                            </Col>
                          </Row>
                          <SeparatorEmpty />

                          <Row>
                            <Col lg={24} md={24}>
                              <CheckBox
                                isChecked={
                                  data.projectSettings.isEnableDocumentDump
                                }
                                onClick={handleChangeProjectBoolSettings.bind(
                                  null,
                                  "isEnableDocumentDump"
                                )}>
                                Logowanie dokumentów
                              </CheckBox>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg={24} md={24}>
                              <CheckBox
                                isChecked={
                                  data.projectSettings
                                    .isEnableSaveDocumentWithoutPrint
                                }
                                onClick={handleChangeProjectBoolSettings.bind(
                                  null,
                                  "isEnableSaveDocumentWithoutPrint"
                                )}>
                                Możliwość zapisu dokumentów bez wymogu
                                wydrukowania
                              </CheckBox>
                            </Col>
                          </Row>
                          <SeparatorEmpty />

                          {/*                          <Row>
                            <Col lg={24} md={24}>
                              <Checkbox
                                disabled={true}
                                isChecked={true}
                                onClick={handleChangeProjectBoolSettings.bind(
                                  null,
                                  "isEnablePayer"
                                )}>
                                Sekcja nabywca na dokumencie (Faktury)
                              </Checkbox>
                            </Col>
                          </Row>*/}

                          <Row>
                            <Col lg={24} md={24}>
                              <CheckBox
                                isChecked={
                                  data.projectSettings.isEnableRecipient
                                }
                                onClick={handleChangeProjectBoolSettings.bind(
                                  null,
                                  "isEnableRecipient"
                                )}>
                                Sekcja odbiorca na dokumencie (Faktury)
                              </CheckBox>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg={24} md={24}>
                              <CheckBox
                                isChecked={data.projectSettings.isEnableNip}
                                onClick={handleChangeProjectBoolSettings.bind(
                                  null,
                                  "isEnableNip"
                                )}>
                                Sekcja nip na dokumencie (Paragony)
                              </CheckBox>
                            </Col>
                          </Row>
                        </Section>
                        <SeparatorEmpty />
                      </Col>
                    </>
                  )}
                  {data.projectSettings.isEnableOrder && (
                    <>
                      <Col lg={12 * projectSetColMultiplier} md={24} xs={24}>
                        <Section title={"Opcje zamówień"}>
                          <Row>
                            <Col lg={12} md={24}>
                              <Input
                                name="numberOrderPattern"
                                value={data.projectSettings.numberOrderPattern}
                                placeholder={"Szablon numeracji zamówień"}
                                onChange={(event, value) =>
                                  handleInputChange("numberOrderPattern", value)
                                }
                                state={data}
                                errors={errors?.projectSettings}
                              />
                            </Col>
                            <Col lg={12} md={24}>
                              <Input
                                type="number"
                                name="showOrderCount"
                                value={data.projectSettings.showOrderCount}
                                placeholder={"Ilość zamówień historycznych"}
                                onChange={(event, value) =>
                                  handleInputChange(
                                    "showOrderCount",
                                    value,
                                    true
                                  )
                                }
                                state={data}
                                errors={errors?.projectSettings}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={24} md={24} xs={24}>
                              <Select
                                placeholder={"Domyślny dystrybutor"}
                                name={"projectStatusId"}
                                value={
                                  data.projectSettings.defaultDistributorId
                                }
                                options={form.distributors.options}
                                onChange2={(selectedOption) => {
                                  setData({
                                    ...data,
                                    projectSettings: {
                                      ...data.projectSettings,
                                      defaultDistributorId:
                                        selectedOption.value.toString(),
                                    },
                                  });
                                }}
                                errors={errors}
                              />
                            </Col>
                          </Row>
                        </Section>
                      </Col>
                    </>
                  )}
                </Row>
              </>
            )}

            <Grid fluid>
              <Row style={{ marginTop: "30px" }}>
                <Col lg={12} md={24} xs={24} style={{ marginBottom: "20px" }}>
                  <Section title={"Opcje wizyty"}>
                    <CheckBox
                      isChecked={data.projectSettings.isEnableTimer}
                      onClick={handleChangeProjectBoolSettings.bind(
                        null,
                        "isEnableTimer"
                      )}>
                      Pomiar czasu wizyty
                    </CheckBox>
                    <CheckBox
                      isChecked={data.projectSettings.isEnableSynchroGps}
                      onClick={handleChangeProjectBoolSettings.bind(
                        null,
                        "isEnableSynchroGps"
                      )}>
                      Synchronizacja Gps
                    </CheckBox>
                    <CheckBox
                      isChecked={data.projectSettings.isEnableOneTimeVisits}
                      onClick={handleChangeProjectBoolSettings.bind(
                        null,
                        "isEnableOneTimeVisits"
                      )}>
                      Wizyty jednorazowe
                    </CheckBox>
                    <CheckBox
                      isChecked={
                        data.projectSettings.isEnableDownloadPhotoFromDisk
                      }
                      onClick={handleChangeProjectBoolSettings.bind(
                        null,
                        "isEnableDownloadPhotoFromDisk"
                      )}>
                      Pobieranie zdjęć z dysku
                    </CheckBox>
                    <CheckBox
                      isChecked={
                        data.projectSettings.isEnableOnlyLocationFromActivity
                      }
                      onClick={handleChangeProjectBoolSettings.bind(
                        null,
                        "isEnableOnlyLocationFromActivity"
                      )}>
                      Pobieranie lokalizacji tylko z aktywności
                    </CheckBox>
                    <CheckBox
                      isChecked={data.projectSettings.isEnableSortByHighlight}
                      onClick={handleChangeProjectBoolSettings.bind(
                        null,
                        "isEnableSortByHighlight"
                      )}>
                      Sortowanie produktów wyróżnionych
                    </CheckBox>
                    <CheckBox
                      isChecked={data.projectSettings.isEnableCountKilometers}
                      onClick={handleChangeProjectBoolSettings.bind(
                        null,
                        "isEnableCountKilometers"
                      )}>
                      Kilometry
                    </CheckBox>
                    <CheckBox
                      isChecked={
                        data.projectSettings.isEnableHistoryQuestionAnswers
                      }
                      onClick={handleChangeProjectBoolSettings.bind(
                        null,
                        "isEnableHistoryQuestionAnswers"
                      )}>
                      Pobieraj odpowiedzi z wizyt historycznych
                    </CheckBox>
                    <CheckBox
                      isChecked={data.projectSettings.isEnableVisitRate}
                      onClick={handleChangeProjectBoolSettings.bind(
                        null,
                        "isEnableVisitRate"
                      )}>
                      Ocena wizyty
                    </CheckBox>
                    <Row>
                      <Col xs={24}>
                        <Input
                          type={"number"}
                          name="gpsDistance"
                          value={data.projectSettings.gpsDistance}
                          placeholder={
                            "Akceptowalna odległość od lokalizacji (GPS)"
                          }
                          onChange={(event, value) =>
                            handleInputChange("gpsDistance", value)
                          }
                          state={data}
                          errors={errors?.projectSettings}
                        />
                      </Col>

                      <Col xs={24}>
                        <Input
                          type={"number"}
                          name="antennaDistance"
                          value={data.projectSettings.antennaDistance}
                          placeholder={
                            "Akceptowalna odległość od lokalizacji (Antena)"
                          }
                          onChange={(event, value) =>
                            handleInputChange("antennaDistance", value)
                          }
                          state={data}
                          errors={errors?.projectSettings}
                        />
                      </Col>
                    </Row>
                    <SeparatorEmpty />
                  </Section>
                </Col>
                <Col lg={12} md={24} xs={24} style={{ marginBottom: "20px" }}>
                  <Section title={"Listingi"}>
                    <CheckBox
                      isChecked={
                        data.projectSettings.isEnableItemNetworkListing
                      }
                      onClick={handleChangeProjectBoolSettings.bind(
                        null,
                        "isEnableItemNetworkListing"
                      )}>
                      Listing, produkty - sieci
                    </CheckBox>
                    <CheckBox
                      isChecked={
                        data.projectSettings.isEnableQuestionNetworkListing
                      }
                      onClick={handleChangeProjectBoolSettings.bind(
                        null,
                        "isEnableQuestionNetworkListing"
                      )}>
                      Listing, pytania - sieci
                    </CheckBox>
                    <CheckBox
                      isChecked={
                        data.projectSettings.isEnableItemLevelNetworkListing
                      }
                      onClick={handleChangeProjectBoolSettings.bind(
                        null,
                        "isEnableItemLevelNetworkListing"
                      )}>
                      Listing, minimalne stany produktów
                    </CheckBox>
                    <CheckBox
                      isChecked={
                        data.projectSettings.isEnableItemHighlightNetworkListing
                      }
                      onClick={handleChangeProjectBoolSettings.bind(
                        null,
                        "isEnableItemHighlightNetworkListing"
                      )}>
                      Listing, produkty wyróżnione
                    </CheckBox>
                  </Section>
                </Col>
                <Col lg={12} md={24} xs={24}>
                  <Section title={"Opcje generowania wizyt"}>
                    <CheckBox
                      isChecked={data.projectSettings.isEnableTaskGrouping}
                      onClick={handleChangeProjectBoolSettings.bind(
                        null,
                        "isEnableTaskGrouping"
                      )}>
                      Grupowanie zadań na wizycie
                    </CheckBox>
                    <CheckBox
                      isChecked={
                        data.projectSettings.isVisitOutOfPlanByLocation
                      }
                      onClick={handleChangeProjectBoolSettings.bind(
                        null,
                        "isVisitOutOfPlanByLocation"
                      )}>
                      Poza planem - tylko aktywności z lokalizacji
                    </CheckBox>
                  </Section>
                </Col>
              </Row>
            </Grid>

            <SeparatorEmpty size={1} />

            <Section title={"Opcje zdjęć"}>
              <Grid fluid>
                <Row gutter={50}>
                  <Col md={6} xs={24}>
                    <label>
                      Jakość zdjęcia (kompresja{" "}
                      {data.projectSettings.photoQuality}%)
                    </label>
                    <div className={styles.photoSettingsSlider}>
                      <Slider
                        defaultValue={data.projectSettings.photoQuality}
                        min={50}
                        max={100}
                        step={5}
                        progress
                        style={{ marginTop: "10px" }}
                        onChange={(value) => {
                          setData({
                            ...data,
                            projectSettings: {
                              ...data.projectSettings,
                              photoQuality: value,
                            },
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6} xs={24}>
                    <label>
                      Rozdzielczość ({data.projectSettings.photoResolution} px)
                    </label>
                    <div className={styles.photoSettingsSlider}>
                      <Slider
                        defaultValue={data.projectSettings.photoResolution}
                        min={1024}
                        max={4096}
                        step={1024}
                        progress
                        graduated
                        style={{ marginTop: "10px" }}
                        renderMark={(m) => (
                          <span className={styles.photoSettingsSliderMark}>
                            {m} px
                          </span>
                        )}
                        onChange={(value) => {
                          setData({
                            ...data,
                            projectSettings: {
                              ...data.projectSettings,
                              photoResolution: value,
                            },
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6} xs={24}>
                    <label>
                      Maksymalny rozmiar ({data.projectSettings.photoMaxSize}{" "}
                      Mb)
                    </label>
                    <div className={styles.photoSettingsSlider}>
                      <Slider
                        defaultValue={data.projectSettings.photoMaxSize}
                        min={1}
                        max={20}
                        step={2}
                        progress
                        style={{ marginTop: "10px" }}
                        onChange={(value) => {
                          setData({
                            ...data,
                            projectSettings: {
                              ...data.projectSettings,
                              photoMaxSize: value,
                            },
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                {waterMarkOptions()}
                <Col md={24}>
                  <FolderPathPicker
                    photoFilenamePattern={
                      data.projectSettings.photoFilenamePattern
                    }
                  />
                </Col>
                <SeparatorLine size={3}></SeparatorLine>
                <Row gutter={50}>
                  <Col md={24}>
                    <Input
                      name="photoFilenamePattern"
                      value={data.projectSettings.photoFilenamePattern}
                      placeholder={"Szablon nazewnictwa plików zdjęciowych"}
                      onChange={(event, value) => {
                        value = value
                          .toString()
                          .replace(/[^a-zA-Z0-9{}/_-]/g, "");
                        handleInputChange("photoFilenamePattern", value);
                      }}
                      state={data}
                      errors={errors?.projectSettings}
                      hint={
                        "niedozwolone znaki ; : ( ) \\, znak '/' tworzy pliki zdjęciowe w folderach archiwum .zip"
                      }
                    />
                  </Col>
                </Row>
              </Grid>
            </Section>

            <SeparatorEmpty size={2} />

            <DoubleSidePicker
              heading={"Pytania do lokalizacji"}
              itemType={"projectLocationQuestion"}
              emptyMessageAssigned={"Nie przypisano żadnych lokalizacji"}
              emptyMessageNotAssigned={"Brak pytań w projekcie"}
              assigned={form.assignedQuestions.data}
              unassigned={form.unassignedQuestions.data}
              clearPickedItemTrigger={clearPickedQuestionTrigger}
              pickedItemCallback={handlePickedQuestion}
              onChange={(assignedIds, unAssignedIds) =>
                doubleSidePickerOnChange(
                  assignedIds,
                  unAssignedIds,
                  "assignedQuestions",
                  "unassignedQuestions",
                  form,
                  setForm
                )
              }
            />
            <ActionsContainer style={{ marginTop: "16px" }}>
              <Button appearance={"primary"} type={"submit"}>
                Zapisz {onTrip && <>i kontynuuj</>}
              </Button>
            </ActionsContainer>
          </Form>
        )}
      </WhiteCard>

      <UniversalModal
        title={getQuestionConfigTitle(pickedQuestion)}
        open={modalOpen}
        body={getModalBody()}
        displayFooterButtons={false}
      />
    </>
  );
};

export default Basic;
